import React from 'react'
import { useTranslation, Link } from 'gatsby-plugin-react-i18next'
import { Button, Stack } from '@chakra-ui/react'

import { Article, H1, Section } from '../ui'

import Layout from '../components/layout'
import SEO from '../components/seo'

const MenuButton = ({ link, children, ...restProps }) => (
  <Button
    as="a"
    borderRadius="100px"
    height="50px"
    fontSize="3xl"
    backgroundColor="#dbc4ab"
    color="brand"
    href={link}
    size="lg"
    target="_blank"
    _hover={{ backgroundColor: '#dbc4ab' }}
    {...restProps}
  >
    {children}
  </Button>
)

function MenuPage({ data }) {
  const { t } = useTranslation()

  return (
    <Layout disableFloatingBanner disablePopup variantHeader="solid">
      <SEO title={t('menu:title')} />
      <Article backgroundColor="brand">
        <Section color="white" textAlign="center" paddingTop={4}>
          <Stack spacing={5} marginX="auto" maxWidth="420px" width="100%">
            <H1 color="initial">{t('menu:title')}</H1>
            <MenuButton link={t('menu:drinks.link')}>{t('menu:drinks.button')}</MenuButton>
            <MenuButton link={t('menu:food.link')}>{t('menu:food.button')}</MenuButton>
            <MenuButton link={t('menu:breakfast.link')}>{t('menu:breakfast.button')}</MenuButton>
            <MenuButton link={t('menu:hookah.link')}>{t('menu:hookah.button')}</MenuButton>
          </Stack>
        </Section>
      </Article>
    </Layout>
  )
}

export default MenuPage
